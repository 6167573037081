import axios, {AxiosResponse} from "axios";
import {Configuration, OrderServiceApi, UserServiceApi, VendingMachineServiceApi} from "./typescript-axios";

interface ResponseInterceptors {
    onFulfilled: ((response: AxiosResponse) => AxiosResponse) | null
    onRejected: ((response: any) => any) | null
}

let responseInterceptors: ResponseInterceptors = {
    onFulfilled: null,
    onRejected: null,
}

export class InterceptorError{
    message : string

    constructor(message: string) {
        this.message = message;
    }
}

export function setupOnFulfilledInterceptor(fulfilledInterceptor: ((response: AxiosResponse<any, any>) => AxiosResponse<any, any>) | null) {
    responseInterceptors.onFulfilled = fulfilledInterceptor;
}

export function setupOnRejectedInterceptor(rejectedInterceptor: ((response: any) => any) | null) {
    responseInterceptors.onRejected = rejectedInterceptor;
}

export const alcoholUserServiceApi = (function (){
    const authToken = localStorage.getItem("authToken");

    const instance = axios.create({
        headers: {
            'content-type': 'application/json',
            "authorization": (authToken === null ? "" : `Bearer ${authToken}`)
        }
    });
    instance.interceptors.response.use(responseInterceptors.onFulfilled ?? function (response){
        return response
    }, responseInterceptors.onRejected ?? function (response){
        return response
    })
    return new UserServiceApi(new Configuration(), window.location.origin, instance);
} as unknown) as {new ():UserServiceApi}

export const alcoholVendingMachineServiceApi = (function (){
    const authToken = localStorage.getItem("authToken");

    const instance = axios.create({
        headers: {
            'content-type': 'application/json',
            "authorization": (authToken === null ? "" : `Bearer ${authToken}`)
        }
    });
    instance.interceptors.response.use(responseInterceptors.onFulfilled ?? function (response){
        return response
    }, responseInterceptors.onRejected ?? function (response){
        return response
    })
    return new VendingMachineServiceApi(new Configuration(), window.location.origin, instance);
} as unknown) as {new ():VendingMachineServiceApi}


export const alcoholOrderServiceApi = (function (){
    const authToken = localStorage.getItem("authToken");

    const instance = axios.create({
        headers: {
            'content-type': 'application/json',
            "authorization": (authToken === null ? "" : `Bearer ${authToken}`)
        }
    });
    instance.interceptors.response.use(responseInterceptors.onFulfilled ?? function (response){
        return response
    }, responseInterceptors.onRejected ?? function (response){
        return response
    })
    return new OrderServiceApi(new Configuration(), window.location.origin, instance);
} as unknown) as {new ():OrderServiceApi}
