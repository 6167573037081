import React, { useEffect, useState } from 'react';
import { InterceptorError, alcoholOrderServiceApi, alcoholUserServiceApi, alcoholVendingMachineServiceApi } from "./api";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Alcoholcustomerv1VendingMachine, Alcoholcustomerv1VerificationState } from "./typescript-axios";

function MachinePage() {
    const [vendingMachine, setVendingMachine] = useState<Alcoholcustomerv1VendingMachine>();
    const [vendingInProgress, setVendingInProgress] = useState<boolean>(false);
    const navigate = useNavigate()
    const params = useParams();

    const [loading, setLoading] = useState(true);

    const findVendingMachine = () => {
        localStorage.removeItem("last_scanned_vending_machine_uuid")
        setLoading(true);

        const apiService = new alcoholVendingMachineServiceApi();

        apiService.vendingMachineServiceRetrieveVendingMachineDetails(params.uuid ?? "", {}).then((response) => {
            if (response.data.success) {
                setVendingMachine(response.data.vendingMachine)
            } else {
                alert("Vending Machine does not exist")
            }
        }).finally(() => {
            setLoading(false);
        })
    }
    useEffect(findVendingMachine, [])  // eslint-disable-line react-hooks/exhaustive-deps

    const [alcoholVerified, setAlcoholVerified] = React.useState<boolean>(false);

    const queryUserDetail = () => {
        const apiService = new alcoholUserServiceApi()
        apiService.userServiceRetrieveMyDetails({}).then((response) => {
            if (response.data.success) {
                if (response.data.myDetails?.verificationState === Alcoholcustomerv1VerificationState.Verified) {
                    setAlcoholVerified(true);
                }
            } else {
                window.alert(response.data.errorMessage)
            }
        }).catch((result) => {
            if (result instanceof InterceptorError) {
                window.alert(result.message)
            } else {
                window.alert("Unknown server error, please retry")
            }
        });
    }

    useEffect(queryUserDetail, [])  // eslint-disable-line react-hooks/exhaustive-deps


    const lockVendingMachine = () => {
        if (vendingInProgress) {
            return;
        }
        setVendingInProgress(true);
        const apiService = new alcoholOrderServiceApi();
        apiService.orderServiceStartOrder({
            vendingMachineUuid: vendingMachine?.uuid ?? ""
        }).then((response) => {
            if (response.data.success) {
                navigate(`/orders/${response.data.orderUuid}`)
            } else {
                setVendingInProgress(false);
                alert("Unable to dispense, please try again later")
            }
        }).catch((result) => {
            setVendingInProgress(false);
        })
    }

    return (
        <div className={"container"}>
            <div className={"mt-5 backgroundSecondary machineDisplay"} style={{ minHeight: 400 }}>
                <div className={"container text-center py-3"}>
                    <img src={vendingMachine?.photoUrl} alt="" className={"mw-100"} />
                </div>
                <div className={"container text-center pb-3"}>
                    <h5 className={"machineName"}>
                        {vendingMachine?.name}
                    </h5>
                    <p className={"machineAddress"}>{vendingMachine?.location}</p>
                </div>
            </div>

            <div className={"pt-3 text-center"} style={{ paddingBottom: "10px" }}>
                <div className="d-grid mw-100 buttonPrimary rounded" style={{ minHeight: "50px" }}>
                    {!loading &&
                        <button className="textButton btn" onClick={lockVendingMachine}>
                            {!vendingInProgress && <div className={"row align-items-center"}>
                                <div className={"col-10"}>

                                    {vendingMachine?.isAlcoholBusinessHourNow && alcoholVerified ?
                                        <div className={"text-start buttonText"}>Start Order</div>
                                        :
                                        <div className={"text-start buttonText"}>Start Order - non-alcohol only</div>
                                    }
                                </div>
                                <div className={"col-2"}>
                                    <i className="fas fa-arrow-right text-end" />
                                </div>
                            </div>}
                            {vendingInProgress && <i className="fas fa-spinner fa-spin" />}
                        </button>
                    }
                </div>
                <br />
                <Link to={"/machines"}>Back</Link>
            </div>
        </div>
    );
}

export default MachinePage;
