import './App.scss';
import {Route, Routes} from "react-router-dom";
import VerificationPage from "./verification_page";
import SignInPage from "./sign_in_page";
import DashboardPage from "./dashboard_page";
import {useEffect, useState} from "react";
import {AuthenticationContext} from "./authentication";
import {AuthenticationProtected} from "./authentication_protected";
import MachinePage from "./machine_page";
import OrderPage from "./order";
import {InterceptorError, setupOnFulfilledInterceptor, setupOnRejectedInterceptor} from "./api";
import InstructionPage from "./instruction_page";
import VendingMachineListPage from "./vending_machine_list_page";

function App() {
    const [authentication, setAuthentication] = useState({
        authenticated:null,
        mobile:null,
        authentication_token:null
    });

    const login = (token, mobile)=>{
        localStorage.setItem("mobile", mobile);
        localStorage.setItem("authToken", token);

        _login(token, mobile)
    }

    const _login = (token, mobile)=>{
        if (token != null){
            setAuthentication({
                authenticated: true,
                mobile: mobile,
                authorization_token: token
            })
        } else {
            logout()
        }
    }

    const logout = () => {
        setAuthentication({
            authenticated: false,
            mobile: "",
            authorization_token: ""
        })
        localStorage.removeItem("mobile");
        localStorage.removeItem("token");
    }

    const init = () => {
        let mobile = localStorage.getItem("mobile");
        let token = localStorage.getItem("authToken");
        setupOnFulfilledInterceptor((response) => {
            return response
        })
        setupOnRejectedInterceptor((_error) => {
            if (_error.response.status === 401){
                console.log("logout", _error.response.status)
                logout();
                throw new InterceptorError("invalid user credential, logout")
            }
            return _error
        })



        if (mobile != null && token != null){
            login(token, mobile)
        } else {
            setAuthentication({
                authenticated:false,
                mobile:null,
                authentication_token:null
            })
        }
    }

	// eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(init, [])


  return (
      <div className={"d-flex flex-column sticky-footer-wrapper min-vh-100 backgroundPrimary"}>
          <AuthenticationContext.Provider value={{
              authentication:authentication,
              logout:logout,
              login:login
          }}>
              <Routes>
                  <Route exact path="sign_in" element={<SignInPage/>}/>
                  <Route exact path="verification" element={<VerificationPage/>}/>
                  <Route exact path="instructions" element={<AuthenticationProtected><InstructionPage/></AuthenticationProtected>}/>
                  <Route exact path="/" element={<AuthenticationProtected><DashboardPage/></AuthenticationProtected>}/>
                  <Route exact path="/machines" element={<AuthenticationProtected><VendingMachineListPage/></AuthenticationProtected>}/>
                  <Route exact path="machines/:uuid" element={<AuthenticationProtected><MachinePage/></AuthenticationProtected>}/>
                  <Route exact path="orders/:txnref" element={<AuthenticationProtected><OrderPage/></AuthenticationProtected>}/>
              </Routes>
          </AuthenticationContext.Provider>
          {/*<Footer/>*/}
      </div>
  );
}

export default App;
