import React, { useEffect, useState } from 'react';
import {Link} from "react-router-dom";
import { alcoholUserServiceApi, InterceptorError } from './api';
import { Alcoholcustomerv1VerificationState } from './typescript-axios/api';

interface Props {

}

function InstructionPage(props: Props) {
    const isRedirectToMachinePage = () => {
        const lastScannedVendingMachineUuid = getLastScannedVendingMachineUuid()
        return lastScannedVendingMachineUuid !== "";
    }

    const getLastScannedVendingMachineUuid = () => {
      return localStorage.getItem("last_scanned_vending_machine_uuid") ?? "";
    }

    const [loading, setLoading] = useState(true);
    const [verified, setVerified] = React.useState<boolean>(false);

    const queryUserDetail = ()=>{
        setLoading(true);
        const apiService = new alcoholUserServiceApi()
        apiService.userServiceRetrieveMyDetails({}).then((response)=>{
            if (response.data.success){
                if (response.data.myDetails?.verificationState === Alcoholcustomerv1VerificationState.Verified) {
                    setVerified(true);
                }
            } else {
                window.alert(response.data.errorMessage)
            }
        }).catch((result)=>{
            if (result instanceof InterceptorError){
                window.alert(result.message)
            } else {
                window.alert("Unknown server error, please retry")
            }
        }).finally(()=>{
            setLoading(false);
        });
    }

    useEffect(queryUserDetail, [])  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className={"container py-3 backgroundSecondary"}>
                <div className={"footText1"}>
                    <h5>
                        Alcohol Vending User Instructions
                    </h5>
                </div>
                <div className="form-floating mb-3">
                    <ol>
                        <li>
                            {loading && "Loading verification state..."}
                            {!loading && !verified && "Congratulations, you are registered to purchase all products except alcohol. Please contact a staff member if you wish to purchase alcohol. To purchase an item select Continue"}
                            {!loading && verified && "Congratulations, you are registered to purchase all products including alcohol. To purchase an item select Continue."}
                        </li>
                        <li>
                            You will see a photo of the vending machine - if correct, select Start Order.
                        </li>
                        <li>
                            Enter your product selection on the vending machine keypad/touchscreen.
                        </li>
                        <li>
                            A Payment Information screen will appear on your phone, Select your payment method and follow the instructions.
                        </li>
                    </ol>
                </div>
            </div>
            <div className={"backgroundPrimary py-4"}>
                <div className={"container"}>
                    <div className={"container pt-3 text-center"}>
                        <div className="d-grid mw-100 buttonPrimary rounded">
                            {isRedirectToMachinePage() && <Link className={"textButton btn"} to={`/machines/${getLastScannedVendingMachineUuid()}`}>Continue</Link>}
                            {!isRedirectToMachinePage() && <Link className={"textButton btn"} to={"/"}>Continue</Link>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InstructionPage;