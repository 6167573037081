import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {alcoholOrderServiceApi, alcoholUserServiceApi, InterceptorError} from "./api";
import {
    Alcoholcustomerv1CustomerDetails,
    Alcoholcustomerv1VerificationState,
    Alcoholcustomerv1Order, Alcoholcustomerv1OrderStatus
} from "./typescript-axios";
import moment from "moment";
import FormatMoney from "./format_money";


function DashboardPage() {
    const [userDetail, setUserDetail] = useState<Alcoholcustomerv1CustomerDetails | null>(null)
    const [lastOrder, setLastOrder] = useState<Alcoholcustomerv1Order | null>(null)

    const queryUserDetail = ()=>{
        const apiService = new alcoholUserServiceApi()
        apiService.userServiceRetrieveMyDetails({}).then((response)=>{
            if (response.data.success){
                setUserDetail(response.data.myDetails ?? null)
                if (response.data.myDetails?.currentRsaRestriction !== "No RSA Restriction"){
                    queryLastOrder();
                } else {
                    setLastOrder(null)
                }
            } else {
                window.alert(response.data.errorMessage)
            }
        }).catch((result)=>{
            if (result instanceof InterceptorError){
                window.alert(result.message)
            } else {
                window.alert("Unknown server error, please retry")
            }
        })
    }

    const queryLastOrder = () => {
        const apiService = new alcoholOrderServiceApi()
        apiService.orderServiceListOrders({
            orderStatus: Alcoholcustomerv1OrderStatus.VendedSuccess,
            page: 0,
            perPage:1
        }).then((response)=>{
            if (response.data.success){
                if ((response.data.orders?.length ?? 0) > 0){
                    setLastOrder(response.data.orders![0]);
                }
            } else {
                window.alert(response.data.errorMessage)
            }
        }).catch((result)=>{
            if (result instanceof InterceptorError){
                window.alert(result.message)
            } else {
                window.alert("Unknown server error, please retry")
            }
        })
    }

    useEffect(()=>{
        queryUserDetail();
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div>
            <div className={"backgroundPrimary pt-5 pb-3"}>
                <div className={"container"}>
                    <h1 className="text-start dashboardHeader ">Alcohol Vending Machine List</h1>
                </div>
                <div className={"container"}>
                    <p className="text-start ">Mobile: <b>{userDetail?.mobilePhoneNumber}</b></p>
                    <p className={"text-start"}>
                        Verification Status Non-Alcohol Products – <b>Verified</b>
                    </p>
                    <p className="text-start ">
                        Verification Status Alcohol Products - <b>{userDetail?.verificationState === Alcoholcustomerv1VerificationState.Verified ? "Verified" : "Unverified"}</b>
                        <br/>
                        <small>You can only purchase alcoholic drinks after verifying your age with an approved staff member.</small>
                    </p>
                    <p className="text-start ">
                        Verification Expires At: <b>{userDetail?.verificationState === Alcoholcustomerv1VerificationState.Verified ? moment.unix(userDetail.verificationExpiredEpoch ?? 0).format("ddd DD MMM YYYY HH:mm:ss z") : "-"}</b>
                        <br/>
                        <small>For security reasons and your protection, your verification expires after a period of time, to reinstate your status, please contact a staff member.</small>
                    </p>
                    <p className="text-start ">RSA Status: <b>{userDetail?.currentRsaRestriction}</b></p>
                    <p className="text-start "><b>{userDetail?.numberOfAlcoholInLastHour}</b> alcohol drink(s) in last hour</p>
                    <p className="text-start "><b>{userDetail?.numberOfAlcoholInLast24Hours}</b> alcohol drink(s) in last 24 hours</p>

                    {lastOrder !== null && <table className="table">
                        <tbody>
                        <tr>
                            <th scope="row">Time</th>
                            <td>{moment.unix(lastOrder.createdEpoch ?? 0).format("ddd DD MMM YYYY HH:mm:ss z")}</td>
                        </tr>
                        <tr>
                            <th scope="row">Selection No</th>
                            <td>{lastOrder?.selection?.displayCode}</td>
                        </tr>
                        <tr>
                            <th scope="row">Selection Name</th>
                            <td>{lastOrder?.selection?.productName}</td>
                        </tr>
                        <tr>
                            <th scope="row">Price</th>
                            <td><FormatMoney cents={lastOrder?.selection?.price || 0} /></td>
                        </tr>
                        <tr>
                            <th scope="row">Discount</th>
                            <td><FormatMoney cents={lastOrder?.discount || 0} /></td>
                        </tr>
                        <tr>
                            <th scope="row">Surcharge</th>
                            <td><FormatMoney cents={lastOrder?.surcharge || 0} /></td>
                        </tr>
                        </tbody>
                    </table>}
                </div>
            </div>
            <div className={"pt-3 text-center"}>
                <Link to={"/machines"}>See all available vending machines</Link>
            </div>

            <div className={"pt-3 text-center"} style={{paddingBottom: "10px"}}>
                <div className="d-grid mw-100 buttonPrimary rounded" style={{minHeight: "50px"}}>
                    <button className="textButton btn" onClick={(e)=>{
                        e.preventDefault();
                        e.stopPropagation();
                        localStorage.removeItem("authToken")
                        localStorage.removeItem("mobile")
                        window.location.reload();
                    }}>
                        {<div className={"row align-items-center"}>
                            <div className={"col-10"}>
                                <div className={"text-start buttonText"}>Logout</div>
                            </div>
                        </div>}
                    </button>
                </div>
            </div>
            <div className="floating position-absolute bottom-0 end-0 rounded-circle m-5">
                <Link type="button" className="btn btn-warning btn-lg dropdown-toggle hide-toggle"
                        data-bs-toggle="dropdown" aria-expanded="false" aria-haspopup="true" to={"/instructions"}>
                    <span className="">Help</span>
                </Link>
            </div>
        </div>
    );
}

export default DashboardPage;
