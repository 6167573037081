import React, {useEffect, useState} from 'react';
import {alcoholVendingMachineServiceApi, InterceptorError} from "./api";
import {Alcoholcustomerv1VendingMachine} from "./typescript-axios";
import VendingMachineListItem from "./vending_machine_list_item";
import {Link, useNavigate} from "react-router-dom";

interface Props {

}

function VendingMachineListPage(props: Props) {
    const [vendingMachines, setVendingMachines] = useState<Alcoholcustomerv1VendingMachine[]>([])
    const navigate = useNavigate()

    const listVendingMachine = ()=>{
        const apiService = new alcoholVendingMachineServiceApi()
        apiService.vendingMachineServiceListVendingMachines({}).then((response)=>{
            if (response.data.success){
                console.log("response", response)
                setVendingMachines(response.data.vendingMachines ?? [])
            } else {
                window.alert(response.data.errorMessage)
            }
        }).catch((result)=>{
            if (result instanceof InterceptorError){
                window.alert(result.message)
            } else {
                window.alert("Unknown server error, please retry")
            }
        })
    }
    useEffect(()=>{
        listVendingMachine();
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    const vmRows = vendingMachines.map((vendingMachine, index)=>{
        console.log("index", index)
        return <VendingMachineListItem key={index}
                                       vendingMachine={vendingMachine}
                                       onClick={()=>{
                                           navigate(`/machines/${vendingMachine.uuid}`)
                                       }}/>
    })
    return (
        <div>
            <div className={"backgroundPrimary pt-5 pb-3"}>
                <div className={"container"}>
                    <h1 className="text-start dashboardHeader ">Alcohol Vending Machine List</h1>
                </div>
            </div>
            <div className={"container pt-4 text-center backgroundSecondary"}>
                {vmRows}
            </div>
            <div className={"pt-3 text-center"} style={{paddingBottom: "10px"}}>
                <Link to={"/"}>Back</Link>
            </div>
        </div>
    );
}

export default VendingMachineListPage;