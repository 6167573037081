import React from "react";

interface Props {
    cents: number
}

function FormatMoney(props:Props) {
    let amount = (props.cents || 0) / Math.pow(10, 2);

    return (
        <span>
            ${amount.toFixed(2)}
        </span>
    );
}

export default FormatMoney;