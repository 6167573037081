import React from 'react';
import {IAuthenticationContext, withAuthentication} from "./authentication";
import {Navigate, matchPath, useLocation, useParams} from "react-router-dom";

interface Props extends IAuthenticationContext {
    children:any
}

function _AuthenticationProtected(props: Props) {
    const location = useLocation();
    const isQrCodeScanRoute = matchPath("/machines/:uuid", location.pathname)
    const params = useParams();
    if (props.authentication.authenticated === null) {
            return (<div/>); // TODO: it should be a spinner
    }

    if (!props.authentication.authenticated) {
        if (isQrCodeScanRoute){
            localStorage.setItem("last_scanned_vending_machine_uuid", params.uuid ?? "")
        }
        return (<Navigate to="/sign_in"/>);
    }

    if (props.authentication.authenticated) {
            return props.children
    }
}

const AuthenticationProtected = withAuthentication(_AuthenticationProtected);
export {AuthenticationProtected};
