import React, {useEffect, useState} from 'react';
import {alcoholOrderServiceApi, InterceptorError} from "./api";
import {useNavigate, useParams} from "react-router-dom";
import {
    Alcoholcustomerv1Order,
    Alcoholcustomerv1OrderStatus,
    V1PaymentFormData
} from "./typescript-axios";
import FormatMoney from './format_money';

function OrderPage() {
    const [order, setOrder] = useState<Alcoholcustomerv1Order | null>(null);
    const [paymentData, setPaymentData] = useState<V1PaymentFormData | null>(null);
    const navigate = useNavigate()
    const params = useParams();

    useEffect(()=>{queryDispenseStatus(params.txnref ?? "")}, [])  // eslint-disable-line react-hooks/exhaustive-deps

    const queryDispenseStatus = (orderTxnRef:string)=>{
        const apiService = new alcoholOrderServiceApi();
        apiService.orderServiceRetrieveOrder(orderTxnRef).then((response)=>{
            if (response.data.success){
                setOrder(response.data.order ?? null);
                if (response.data.order?.status === Alcoholcustomerv1OrderStatus.PendingSelection ||
                    response.data.order?.status === Alcoholcustomerv1OrderStatus.PendingCustomerConfirm ||
                    response.data.order?.status === Alcoholcustomerv1OrderStatus.Vending) {
                    window.setTimeout(() => {
                        queryDispenseStatus(orderTxnRef)
                    }, 1000)
                }
            } else {
                window.alert(response.data.errorMessage)
            }
        }).catch((result)=>{
            if (result instanceof InterceptorError){
                window.alert(result.message)
            } else {
                window.alert("Unknown server error, please retry")
            }
        })
    }

    const cancelVend = () => {
        const apiService = new alcoholOrderServiceApi();
        apiService.orderServiceCancelOrder(order?.uuid ?? "", {}).then((response)=>{
            if (response.data.success){
                navigate(`/`)
            } else {
                window.alert(response.data.errorMessage)
            }
        }).catch((result)=>{
            if (result instanceof InterceptorError){
                window.alert(result.message)
            } else {
                window.alert("Unknown server error, please retry")
            }
        })
    }

    const getPaymentFromData = () => {
        const apiService = new alcoholOrderServiceApi();
        if (order !== null && order.status === Alcoholcustomerv1OrderStatus.PendingCustomerConfirm && paymentData === null){
            apiService.orderServiceGetOrderPaymentData(order?.uuid ?? "").then((response)=>{
                if (response.data.success){
                    setPaymentData(response.data.paymentFormData ?? null)
                } else {
                    window.alert(response.data.errorMessage)
                }
            })
        }
    }

    useEffect(getPaymentFromData, [order, paymentData])

    return (
        <div className={"container"}>

            {order?.status === Alcoholcustomerv1OrderStatus.PendingSelection && <div className={"mt-5 backgroundSecondary machineDisplay"} style={{minHeight:400}}>
                <div className={"container text-center py-3"}>
                    <h5>Please make selection on vending machine</h5>
                </div>
            </div>}

            {order?.status === Alcoholcustomerv1OrderStatus.PendingCustomerConfirm && <div className={"mt-5 backgroundSecondary machineDisplay"} style={{minHeight:400}}>
                <div className={"container text-center py-3"}>
                    <h5>Selection</h5>
                    <table className="table">
                        <tbody>
                        <tr>
                            <th scope="row">Selection No</th>
                            <td>{order?.selection?.displayCode}</td>
                        </tr>
                        <tr>
                            <th scope="row">Selection Name</th>
                            <td>{order?.selection?.productName}</td>
                        </tr>
                        { (order?.selection?.imageUrl !== "") &&
                            <tr>
                                <td colSpan={2}><img src={order?.selection?.imageUrl} width="75%" alt={"Selection"}/></td>
                            </tr>
                        }
                        <tr>
                            <th scope="row">Price</th>
                            <td><FormatMoney cents={order?.selection?.price || 0} /></td>
                        </tr>
                        <tr>
                            <th scope="row">Discount</th>
                            <td><FormatMoney cents={order?.discount || 0} /></td>
                        </tr>
                        <tr>
                            <th scope="row">Surcharge</th>
                            <td><FormatMoney cents={order?.surcharge || 0} /></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>}

            {order?.status === Alcoholcustomerv1OrderStatus.Vending && <div className={"mt-5 backgroundSecondary machineDisplay"} style={{minHeight:400}}>
                <div className={"container text-center py-3"}>
                    <h5>Dispensing... Please Wait</h5>
                </div>
            </div>}

            {order?.status === Alcoholcustomerv1OrderStatus.VendedSuccess && <div className={"mt-5 backgroundSecondary machineDisplay"} style={{minHeight:400}}>
                <div className={"container text-center py-3"}>
                    <h5>Item dropped, please collect it</h5>
                </div>
            </div>}

            {(order?.status === Alcoholcustomerv1OrderStatus.Failure || order?.status === Alcoholcustomerv1OrderStatus.Aborted) && <div className={"mt-5 backgroundSecondary machineDisplay"} style={{minHeight:400}}>
                <div className={"container text-center py-3"}>
                    <h5>{(order?.failedReason === "" || order?.failedReason === null) ? "Vend Failure" : order?.failedReason}</h5>
                </div>
            </div>}

            <div className={"pt-3 text-center"} style={{paddingBottom: "10px"}}>
                { order?.status === Alcoholcustomerv1OrderStatus.PendingSelection &&
                    <div className="d-grid mw-100 buttonPrimary rounded" style={{minHeight: "50px"}}>
                        <button className="textButton btn" onClick={cancelVend}>
                            <div className={"row align-items-center"}>
                                <div className={"col-10"}>
                                    <div className={"text-start buttonText"}>Cancel</div>
                                </div>
                                <div className={"col-2"}>
                                    <i className="fas fa-arrow-right text-end"/>
                                </div>
                            </div>
                        </button>
                    </div>
                }

                { order?.status === Alcoholcustomerv1OrderStatus.PendingCustomerConfirm && paymentData !== null &&
                    <div className="d-grid mw-100 buttonPrimary rounded" style={{minHeight: "50px",marginBottom:"5px"}} >
                        <form target='_blank' id="payment-form" method={"post"} action={paymentData?.paymentUrl} className={"pb-1"}>
                            <input type="hidden" name="Merchant_User_Id" value={paymentData?.merchantUserId} />
                            <input type="hidden" name="merchantpwd" value={paymentData?.merchantPassword} />
                            <input type="hidden" name="merchant_ref_number" value={paymentData?.merchantRefNumber} />
                            <input type="hidden" name="currencydesc" value={paymentData?.currencyDesc} />
                            <input type="hidden" name="amount" value={paymentData?.amount} />
                            <input type="hidden" name="transactionkey" value={paymentData?.transactionKey} />
                            <input type="hidden" name="notifyurl" value={paymentData?.notifyUrl} />
                            <input type="hidden" name="processurl" value={paymentData?.processUrl} />
                            <input type="hidden" name="cancelurl" value={paymentData?.cancelUrl} />
                            <input type="hidden" name="customer_firstname" value="NA" />
                            <input type="hidden" name="customer_lastname" value="NA" />
                            <input type="hidden" name="customer_phone" value="NA" />
                            <input type="hidden" name="customer_email" value="NA"/>
                            <input type="hidden" name="customer_ipaddress" value="NA" />
                            <input type="hidden" name="bill_firstname" value="NA"/>
                            <input type="hidden" name="bill_lastname" value="NA"/>
                            <input type="hidden" name="bill_address1" value="NA" />
                            <input type="hidden" name="bill_address2" value="NA" />
                            <input type="hidden" name="bill_city" value="NA" />
                            <input type="hidden" name="bill_country" value="NA" />
                            <input type="hidden" name="bill_state" value="NA" />
                            <input type="hidden" name="bill_zip" value="NA" />
                            <input type="hidden" name="ship_to_address1" value="NA" />
                            <input type="hidden" name="ship_to_address2" value="NA" />
                            <input type="hidden" name="ship_to_city" value="NA" />
                            <input type="hidden" name="ship_to_country" value="NA" />
                            <input type="hidden" name="ship_to_phone" value="NA" />
                            <input type="hidden" name="ship_to_state" value="NA" />
                            <input type="hidden" name="ship_to_zip" value="NA" />
                            <input type="hidden" name="ship_to_method" value="NA" />
                            <input type="hidden" name="Purchase_summary" value="NA" />
                            <input type="hidden" name="scsscheck" value="" />
                            <input type="hidden" name="defaultview" value="wallet" />
                            <div className="d-grid gap-2" >
                                <button type="submit" className="textButton btn">
                                    <div className={"row align-items-center"}>
                                        <div className={"col-10"}>
                                            <div className={"text-start buttonText"}>Make Payment</div>
                                        </div>
                                        <div className={"col-2"}>
                                            <i className="fas fa-arrow-right text-end"/>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </form>
                    </div>
                }

                { order?.status === Alcoholcustomerv1OrderStatus.PendingCustomerConfirm &&
                    <div className="d-grid mw-100 buttonPrimary rounded" style={{minHeight: "50px"}}>
                        <button className="textButton btn" onClick={cancelVend}>
                            {<div className={"row align-items-center"}>
                                <div className={"col-10"}>
                                    <div className={"text-start buttonText"}>Cancel</div>
                                </div>
                                <div className={"col-2"}>
                                    <i className="fas fa-arrow-right text-end"/>
                                </div>
                            </div>}
                        </button>
                    </div>
                }

                { order?.status === Alcoholcustomerv1OrderStatus.Vending &&
                    <div className="d-grid mw-100 buttonPrimary rounded" style={{minHeight: "50px"}}>
                        <button className="textButton btn" onClick={()=>{}}>
                            {<i className="fas fa-spinner fa-spin"/>}
                        </button>
                    </div>
                }

                { order?.status === Alcoholcustomerv1OrderStatus.VendedSuccess &&
                    <div className="d-grid mw-100 buttonPrimary rounded" style={{minHeight: "50px"}}>
                        <button className="textButton btn" onClick={()=>{
                            navigate("/", {replace:true})
                        }}>
                            {<div className={"row align-items-center"}>
                                <div className={"col-10"}>
                                    <div className={"text-start buttonText"}>Back</div>
                                </div>
                                <div className={"col-2"}>
                                    <i className="fas fa-arrow-right text-end"/>
                                </div>
                            </div>}
                        </button>
                    </div>
                }

                { order?.status === Alcoholcustomerv1OrderStatus.Aborted &&
                    <div className="d-grid mw-100 buttonPrimary rounded" style={{minHeight: "50px"}}>
                        <button className="textButton btn" onClick={()=>{
                            navigate("/", {replace:true})
                        }}>
                            {<div className={"row align-items-center"}>
                                <div className={"col-10"}>
                                    <div className={"text-start buttonText"}>Back</div>
                                </div>
                                <div className={"col-2"}>
                                    <i className="fas fa-arrow-right text-end"/>
                                </div>
                            </div>}
                        </button>
                    </div>
                }

                { order?.status === Alcoholcustomerv1OrderStatus.Failure &&
                    <div className="d-grid mw-100 buttonPrimary rounded" style={{minHeight: "50px"}}>
                        <button className="textButton btn" onClick={()=>{
                            navigate("/", {replace:true})
                        }}>
                            {<div className={"row align-items-center"}>
                                <div className={"col-10"}>
                                    <div className={"text-start buttonText"}>Back</div>
                                </div>
                                <div className={"col-2"}>
                                    <i className="fas fa-arrow-right text-end"/>
                                </div>
                            </div>}
                        </button>
                    </div>
                }
            </div>
        </div>
    );
}

export default OrderPage;